<!--查询工资列表-->
<template>
  <div>
    <div>
      <div>

        <div style="margin-right: 50px">
          <el-select style="margin-left: 5px;margin-bottom: 20px;margin-right: 20px" v-model="unit"
                     placeholder="请选择标段" size="small">
            <el-option v-for="item in bdsts" :key="item.bdstname" :label="item.bdstname" :value="item.bdstname">
            </el-option>
          </el-select>
          <el-date-picker
              v-model="month"
              type="month"
              format="YYYY-MM"
              value-format="YYYY-MM"
              placeholder="选择月份"
              size="small">

          </el-date-picker>
          <el-input style="width: 250px;margin-left: 20px; margin-right: 20px" v-model="username"
                    placeholder="请输入姓名或首字母" clearable size="small"/>
          <el-button type="warning" size="small" @click="getSalaryListByMonth">查询</el-button>

          <el-button type="warning" size="small" @click="sendEmailForList" style="margin-left: 150px">
            批量发送邮件
          </el-button>
        </div>
      </div>
    </div>
    <!--    数据表格-->
    <div>
      <vxe-table
          auto-resize
          border
          resizable
          align="center"
          header-align="center"
          :data="SalaryList"
          size="mini"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column type="seq" width="30" title="序号"></vxe-column>
        <vxe-column field="bdst" title="单位"></vxe-column>
        <vxe-column field="employeeName" title="姓名"></vxe-column>
        <vxe-column field="salaryMonth" title="工资月份"></vxe-column>
        <vxe-colgroup title="岗位基本工资">
          <vxe-column field="gangWeiGongZi" title="岗位工资"></vxe-column>
          <vxe-column field="nianGongGongZi" title="年共工资"></vxe-column>
          <vxe-column field="jiShuZhiChengJinTie" title="技术职称津贴"></vxe-column>
          <vxe-column field="liuDongShiGongJinTie" title="流动施工津贴"></vxe-column>
          <vxe-column field="suiDaoShiGongBuTie" title="隧道施工补贴"></vxe-column>
          <vxe-column field="jianZhiJinTie" title="兼职津贴"></vxe-column>
          <vxe-column field="nvGongWeiShengFei" title="女工卫生费"></vxe-column>
          <vxe-column field="zhiYeZiGeZhengShuJinTie" title="执业资格证书津贴"></vxe-column>
          <vxe-column field="duShengZiNüFei" title="独生子女费"></vxe-column>
          <vxe-column field="yiDongTongXinFei" title="移动通信费"></vxe-column>
          <vxe-column field="tanQinJiaBuZhu" title="探亲假补助"></vxe-column>
          <vxe-column field="fangShuJiangWenFei" title="防暑降温费"></vxe-column>
          <vxe-column field="quNuanBuTie" title="取暖补贴"></vxe-column>
          <vxe-column field="buYiQianYueDuGongZi" title="补以前月度工资"></vxe-column>
          <vxe-column field="qiTa" title="其他"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="考核工资">
          <vxe-column field="anQuanKaoHeXiShu" title="安全考核系数"></vxe-column>
          <vxe-column field="zhiLiangKaoHeXiShu" title="质量考核系数"></vxe-column>
          <vxe-column field="huanBaoKaoHeXiShu" title="环保考核系数"></vxe-column>
          <vxe-column field="yueDuKaoHeGongZi" title="月度考核工资"></vxe-column>
          <vxe-column field="buMenKaoHeGongZi" title="部门考核工资"></vxe-column>
          <vxe-column field="nianDuKaoHeGongZi" title="年度考核工资"></vxe-column>
        </vxe-colgroup>
        <vxe-column field="jiaBanGongZi" title="加班工资"></vxe-column>
        <vxe-column field="jiTuanGongSiZhuanJiangLi" title="集团公司转奖励"></vxe-column>
        <vxe-column field="danXiangJiangLi" title="单项奖励"></vxe-column>
        <vxe-column field="yingFaHeJi" title="应发合计"></vxe-column>
        <vxe-colgroup title="代扣社保">
          <vxe-column field="yangLaoBaoXian" title="养老保险"></vxe-column>
          <vxe-column field="zhuFangGongJiJin" title="住房公积金"></vxe-column>
          <vxe-column field="qiYeNianJin" title="企业年金"></vxe-column>
          <vxe-column field="yiLiaoBaoXian" title="医疗保险"></vxe-column>
          <vxe-column field="shiYeBaoXian" title="失业保险"></vxe-column>
          <vxe-column field="kouHuZhuJin" title="扣互助金"></vxe-column>
        </vxe-colgroup>
        <vxe-column field="geRenSuoDeShui" title="个人所得税"></vxe-column>
        <vxe-column field="shiFaHeJi" title="实发合计"></vxe-column>
        <vxe-column title="操作" show-overflow width="100">
          <template #default="{ row }">
            <vxe-button size="mini" status="success" @click="sendOneMail(row)">发送邮件</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </div>

  <!--    不匹配提示-->
  <div>
    <el-dialog
        v-model="dialogVisible"
        title="不匹配人员邮件可能发送异常"
        width="44%"
    >
      <el-tag v-if="users.length !== 0" type="success">{{ unit }}用户表不存在人员</el-tag>
      <div>
        <el-row :gutter="24">
          <el-col :span="4" v-for="name in users">
            <!--             <el-card v-if="users.length !== 0" v-for="name in users" shadow="always"> {{ name }}</el-card>-->
            <el-card v-if="users.length !== 0" shadow="always"> {{ name }}</el-card>
          </el-col>
        </el-row>
      </div>

      <el-tag v-if="emails.length !== 0" type="danger">邮箱异常人员合计{{ emails.length }}人</el-tag>
      <div v-if="emails.length !== 0">

        <el-table :data="emails">
          <el-table-column label="序号" width="150" type="index">

          </el-table-column>
          <el-table-column property="unit" label="单位" width="150"/>
          <el-table-column property="name" label="姓名" width="200"/>
          <el-table-column property="phone" label="手机号"/>
        </el-table>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <el-button type="info" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="sendUserEmail">无视继续发送</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {setNowMonth2} from "@/utils/time";
import {ElMessage, ElMessageBox} from 'element-plus'

export default {
  name: "Salarysel",
  data() {
    return {
      bdsts: [],
      month: '',
      unit: '',
      SalaryList: [],
      dialogVisible: false,
      users: [],
      emails: [],
      username: ''
    }
  },
  computed: {
    ...mapGetters(['GETBDST'])
  },
  created() {
    this.axios.get('/other/exzxzz', (response) => {
      this.bdsts = response.obj;
      console.log(this.bdsts)
    }, {
      bdst: 1
    });
    // 默认获取当月月份
    this.month = setNowMonth2(new Date());
  },
  methods: {
    // 根据工区、月份查询工资列表
    getSalaryListByMonth() {
      if (this.month !== null & this.month !== "" & this.unit !== "") {
        this.axios.post('/salary/getSalaryListByMonth', (response2) => {
          console.log(response2.obj)
          this.SalaryList = response2.obj
        }, {
          month: this.month,
          bdst: this.unit,
          userName:this.username
        })
      } else {
        ElMessage.error('请选择单位和月份!')
      }

    },
    /**
     * 发送前判断邮件地址和用户名是否异常
     */
    sendEmailPreview() {

    },
    /**
     * 批量推送邮件至用户列表，先查询是否匹配，给出提示
     */
    sendEmailForList() {
      if (this.unit === '') {
        ElMessage.error("请先选择单位！")
      } else {
        this.axios.post('/user/seluser', (response) => {
          console.log(response.obj)
          console.log(response.obj2)
          if (response.obj.length !== 0) {
            this.users = response.obj;
            this.emails = response.obj2;
            this.dialogVisible = true;
          } else {

            ElMessage({
              message: '人员匹配无误，可以发送邮件！',
              type: 'success',
            });
            this.sendUserEmail();
          }
        }, {
          bdst: this.unit,
          month: this.month
        })
      }

    },
    sendUserEmail() {
      this.axios.post('/salary/sendEmailForList', (response) => {
        if (response.code === 200) {
          ElMessage({
            message: "邮件已发送，请至邮件发送历史查询发送结果",
            type: 'success',
          });
        }
      }, {
        bdst: this.unit,
        month: this.month
      })
    },
    //发送单封邮件
    sendOneMail(row) {
      this.axios.post('/salary/sendEmailForList', (response) => {
        if (response.code === 200) {
          ElMessage({
            message: "邮件已发送，请至邮件发送历史查询发送结果",
            type: 'success',
          });
        }
      }, {
        bdst: this.unit,
        month: this.month,
        userName: row.employeeName
      })
    }
  }
}
</script>

<style>
.el-dialog__header {
  background: #002a52;
  text-align: center;
}

.el-dialog__title {
  color: aliceblue;
}

.el-table .el-table__cell {
  padding: 6px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center;
}

.el-tag.el-tag--danger {
  --el-tag-background-color: #fef0f0;
  --el-tag-border-color: #fde2e2;
  --el-tag-font-color: #f56c6c;
  --el-tag-hover-color: #f56c6c;
  margin-top: 8px;
}

.el-tag.el-tag--success {
  --el-tag-background-color: #f0f9eb;
  --el-tag-border-color: #e1f3d8;
  --el-tag-font-color: #67c23a;
  --el-tag-hover-color: #67c23a;
  margin-bottom: 8px;
}

/*.el-card__body {*/
/*  padding: 10px;*/
/*}*/

</style>